<template>
  <div class="vx-row mg-all-0 justify-center">
    <!-- <div
      class="vx-col lg:w-1/4 w-full hiddin-moblView cust-height indiv-left-w  card-bg"
    ></div>-->
    <div
      class="vx-col lg:w-1/2 w-full cust-height indiv-left-w smp_card_card card-bg"
    >
      <vx-card
        class="smp_card_body smp_card_card margin_top_groupspage cust-remboxsh"
        v-show="isLoaded"
      >
        <div class="p-3">
          <div
            class="post-header flex justify-between lg:mb-8 md:mb-8 mb-4 py-3 lg:px-2 md:px-2 px-1 lg:mt-4 md:mt-0"
          >
            <div class="flex items-center smp_top_10">
              <div>
                <!-- <vs-avatar
                v-if="groupPost.usersDetails_UserImage != null"
                class="m-0"
                :src="magittUrl + groupPost.usersDetails_UserImage"
                size="45px"
              ></vs-avatar>-->
                <vs-avatar
                  class="m-0 bg-primary"
                  :text="displayName"
                  size="45px"
                ></vs-avatar>
              </div>
              <div class="ml-4 cust-negtop">
                <h6
                  class="h-5-clsbold smp_cursor-pointer social_feed_slider_user_bold h4-class"
                >
                  {{ displayName }}
                </h6>
                <small class>{{
                  groupPost.post_group_mapping_created_datetimestamp
                }}</small>
              </div>
            </div>
          </div>
          <div class="smp_post_content mt-4 mb-2 lg:p-3 md:p-3 p-1">
            <PollView
              v-show="groupPost.PollQuestionId"
              :votedIds="groupPost.VotedIds"
              :PostId="groupPost.post_group_mapping_post_id"
              :PollId="groupPost.PollQuestionId"
              :Ids="groupPost.AnswersIds"
              :Question="groupPost.PollQuestion"
              :Answers="groupPost.PollAnswers"
              :AnswersVotes="groupPost.AnswersVotes"
            />
            <p
              v-show="groupPost.text != null"
              v-html="groupPost.text"
              class="smp_text_pddd h3-class p-3"
            ></p>
            <p
              v-if="groupPost.imageText"
              v-html="groupPost.imageText"
              class="smp_text_pddd"
            ></p>
            <p
              v-if="groupPost.videoText"
              v-html="groupPost.videoText"
              class="smp_text_pddd"
            ></p>
            <p
              v-if="groupPost.audioText"
              v-html="groupPost.audioText"
              class="smp_text_pddd"
            ></p>
            <h3
              v-if="groupPost.blogText"
              v-html="groupPost.blogText"
              class="smp_text_pddd"
            ></h3>
          </div>
          <div class="post-media-container cust-bgededed flex justify-center">
            <!-- <video-player
            ref="aplayer"
            class
            :options="playerOptionsYoutube(groupPost)"
            v-if="
              groupPost.url != null &&
                (groupPost.url.includes('youtube') ||
                  groupPost.url.includes('youtu.be'))
            "
            v-once
          />-->
            <div id="app" v-if="groupPost.url != null">
              <link-prevue :url="groupPost.url" v-if="groupPost.url != null">
                <template slot="loading">
                  <!-- set your custom loading -->
                </template>
                <template slot-scope="props">
                  <div class="card-block">
                    <h5 class="card-title">{{ props.title }}</h5>
                    <p class="card-text">{{ props.description }}</p>
                    <a
                      v-bind:href="props.url"
                      class="btn btn-primary more-primarycolr"
                      >More</a
                    >
                  </div>
                  <img
                    class="indiv-postimg"
                    :src="
                      props.img
                        ? props.img
                        : (props.img =
                            magittUrl +
                            'uploads/defaultImages/default_post.png')
                    "
                    :alt="props.title"
                    @click="
                      group_page_post_sidebar_active =
                        !group_page_post_sidebar_active;
                      postPopupImage = props.img;
                    "
                  />
                </template>
              </link-prevue>
            </div>
            <img
              class="indiv-postimg"
              :src="groupPost.image"
              @error="
                groupPost.image =
                  magittUrl + 'uploads/defaultImages/default_post.png'
              "
              alt="user-upload"
              v-if="groupPost.image != null"
              @click="
                group_page_post_sidebar_active =
                  !group_page_post_sidebar_active;
                postPopupImage = groupPost.image;
              "
            />
            <img
              class="indiv-postimg"
              :src="groupPost.featured_image"
              @error="
                groupPost.featured_image =
                  magittUrl + 'uploads/defaultImages/default_post.png'
              "
              alt="user-upload"
              v-if="groupPost.featured_image != null"
              @click.stop="
                group_page_post_sidebar_active =
                  !group_page_post_sidebar_active;
                postPopupImage = groupPost.featured_image;
              "
            />
            <video-player
              ref="player"
              class
              :options="playerOptions(groupPost)"
              v-else-if="groupPost.video_url != null"
              v-once
            />
            <audio controls v-else-if="groupPost.audio_url != null">
              <source :src="groupPost.audio_url" />
            </audio>
            <!-- <video-player
            ref="aplayer"
            class
            :options="playerOptionsAudio(groupPost)"
            v-else-if="groupPost.audio_url != null"
            v-once
          />-->
          </div>
          <div
            style="height: 100%"
            class="ql-editor blog-body"
            v-show="groupPost.body != null"
            v-html="groupPost.body"
          ></div>
          <div class="flex justify-center m-4">
            <a
              href="https://api.whatsapp.com/send?phone=912266006022&text=Subscribe"
              target="_blank"
              class="whatsapp-color flex items-center px-4 py-3 rounded-lg"
            >
              <span class="text-white fsize12 font-medium mr-2"
                >Get updates on WhatsApp</span
              >
              <img
                src="@/Core/assets/images/pages/whatsappicon.png"
                class="w-10 h-10"
              />
            </a>
          </div>

          <div>
            <p class="cust-indmb cust-disclm-ind text-justify">
              DISCLAIMER: This communication is confidential and privileged and
              is directed to and for the use of the addressee only. The
              recipient if not the addressee should not use this message if
              erroneously received, and access and use of this e-mail in any
              manner by anyone other than the addressee is unauthorized. If you
              are not the intended recipient, please notify the sender by return
              email and immediately destroy all copies of this message and any
              attachments and delete it from your computer system permanently.
              The recipient acknowledges that Kotak Mahindra Bank Limited may be
              unable to exercise control or ensure or guarantee the integrity of
              the text of the email message and the text is not warranted as to
              completeness and accuracy. Before opening and accessing the
              attachment, if any, please check and scan for virus.
            </p>
          </div>
          <div
            class="flex justify-around my-5"
            v-if="groupPost.post_attachedDocument"
          >
            <DownloadDocuments :path="groupPost.post_attachedDocument" />
          </div>
          <div
            class="flex justify-between smp_mt_4 mt-3 items-center"
            @click="openLoadingContained"
          >
            <div class="flex items-center">
              <div class="flex items-center">
                <!-- <main>
                <input class="l" type="checkbox" />
              </main>-->
                <feather-icon
                  class="mr-3 groups_like_icon"
                  icon="HeartIcon"
                  ref="loadableButton"
                  id="button-with-loading"
                  :svgClasses="{
                    'text-danger fill-current stroke-current':
                      groupPost.is_active,
                  }"
                  @click="
                    likePost(
                      groupPost.is_active,
                      groupPost.postId,
                      groupPost.likeId,
                      groupPost.post_user_id,
                      groupPost.post_UniqueId,
                      groupPost.usersDetails_UniqueId
                    )
                  "
                ></feather-icon>
                <div v-if="$acl.check('editor')">
                  <span
                    v-if="
                      groupPost.LIKECOUNT == null || groupPost.LIKECOUNT == 0
                    "
                    >Like</span
                  >

                  <span v-else-if="groupPost.LIKECOUNT == 1"
                    >{{ groupPost.LIKECOUNT }} Like</span
                  >
                  <span v-else>{{ groupPost.LIKECOUNT }} Likes</span>
                </div>
              </div>
            </div>
            <div
              class="flex items-center lg:mr-4 md:mr-4 mr-2 group_page_comment_icon"
              v-if="$acl.check('editor')"
            >
              <span
                v-if="
                  groupPost.comment_count == null ||
                  groupPost.comment_count == 0
                "
                >No Comments</span
              >
              <span v-else-if="groupPost.comment_count == 1"
                >{{ groupPost.comment_count }} Comment</span
              >
              <span v-else>{{ groupPost.comment_count }} Comments</span>
            </div>
          </div>
          <div class>
            <div>
              <social-sharing
                :url="copyText"
                inline-template
                @open="socialShareOpen"
              >
                <div class="onboard-socialicon h-15 my-4">
                  <div class>
                    <span class="h2-class">Share :</span>
                  </div>
                  <network network="whatsapp">
                    <img
                      src="@/Core/assets/images/pages/carousel/whatsapp.png"
                      class="img-responsive social-icon-img"
                      @open="addSharedPromotionLog('whatsapp')"
                    />
                  </network>
                  <network network="facebook">
                    <img
                      src="@/Core/assets/images/pages/carousel/facebook.png"
                      class="img-responsive social-icon-img"
                      @click="addSharedPromotionLog('facebook')"
                    />
                  </network>
                  <network network="email">
                    <img
                      src="@/Core/assets/images/pages/carousel/new-email-button.png"
                      class="img-responsive social-icon-img"
                    />
                  </network>
                  <network network="linkedin">
                    <img
                      src="@/Core/assets/images/pages/carousel/linkedin.png"
                      class="img-responsive social-icon-img"
                      @click="addSharedPromotionLog('linkedin')"
                    />
                  </network>
                  <network network="twitter">
                    <img
                      src="@/Core/assets/images/pages/carousel/twitter.png"
                      class="img-responsive social-icon-img"
                      @click="addSharedPromotionLog('twitter')"
                    />
                  </network>
                </div>
              </social-sharing>
            </div>
            <div
              class="smp_comments_container smp_mt_4 mt-4"
              v-show="
                groupPost.first_comment ||
                (groupPost.comments && groupPost.comments.length > 0)
              "
            >
              <ul class="smp_user-comments-list">
                <li
                  class="smp_commented_user items-center"
                  v-if="groupPost.first_comment"
                >
                  <div class="parent-userDplabl">
                    <div class="mr-3 pt-2">
                      <vs-avatar
                        v-if="groupPost.first_comment_user_image != null"
                        class="m-0"
                        :src="magittUrl + groupPost.first_comment_user_image"
                        size="30px"
                        :text="groupPost.first_comment_user_name"
                      />
                      <vs-avatar
                        v-if="groupPost.first_comment_user_image == null"
                        class="bg-primary"
                        size="30px"
                        :text="groupPost.first_comment_user_name"
                      />
                    </div>
                    <div class="smp_leading_tight smp_textsocial">
                      <p
                        class="font-medium h4-class smp_cursor-pointer cust-pt-pb cust-pb-5"
                      >
                        {{ groupPost.first_comment_user_name }}
                      </p>
                      <span class="post-comm-spn">{{
                        groupPost.first_comment
                      }}</span>
                    </div>
                  </div>
                  <ul class="smp_user-comments-list" v-if="showReplies">
                    <li
                      class="smp_commented_user items-center ml-2 smp_text_pddd"
                      v-for="comment of groupPost.first_comment_replies"
                      :key="comment.comments_comment"
                    >
                      <div class="parent-userDplabl mb-2 ml-2">
                        <div class="mr-3">
                          <vs-avatar
                            v-if="comment.usersDetails_UserImage != null"
                            class="m-0"
                            :src="magittUrl + comment.usersDetails_UserImage"
                            size="30px"
                            :text="comment.usersDetails_UserName"
                          />
                          <vs-avatar
                            v-if="comment.usersDetails_UserImage == null"
                            class="bg-primary"
                            size="30px"
                            :text="comment.usersDetails_UserName"
                          />
                        </div>
                        <div class="smp_leading_tight smp_textsocial">
                          <p
                            class="font-medium h4-class smp_cursor-pointer cust-pt-pb cust-pb-5"
                          >
                            {{ comment.usersDetails_UserName }}
                          </p>
                          <span class="post-comm-spn">{{
                            comment.replies_comment
                          }}</span>
                        </div>
                      </div>
                    </li>
                  </ul>
                  <div class>
                    <div class="replybtn-parent flex cursor-pointer mb-2">
                      <div class="reply-btn" v-if="!showReply">
                        <a @click.stop="showReply = true">Reply</a>
                      </div>

                      <div class="ml-8 cursor-pointer mb-2">
                        <div
                          class="reply-btn"
                          v-if="groupPost.REPLYCOUNT > 0 && !showReplies"
                        >
                          <a
                            @click.stop="
                              getFirstReplies(groupPost.first_comment_id)
                            "
                            >({{ groupPost.REPLYCOUNT }}) Replies</a
                          >
                        </div>
                      </div>
                    </div>

                    <div class="replybtn-input ml-10 relative flex">
                      <vs-input
                        v-if="showReply"
                        size="small"
                        class="reply-inputbox"
                        placeholder
                        v-model="firstReply"
                        @keydown.enter="
                          addReplyFirstComment(
                            groupPost.post_UniqueId,
                            groupPost.first_comment_id,
                            firstReply
                          )
                        "
                      />
                      <!-- <vs-button
                        class="ml-2"
                        size="small"
                        v-if="showReply"
                        @click="
                          addReplyFirstComment(
                            groupPost.post_UniqueId,
                            groupPost.first_comment_id,
                            firstReply
                          )
                        "
                        >Reply</vs-button
                    >-->
                      <div class="indiv-repindivreply-sendbtn">
                        <i
                          icon="icon-send"
                          class="feather icon-send feather-20 text-primary"
                          v-if="showReply"
                          @click="
                            addReplyFirstComment(
                              groupPost.post_UniqueId,
                              groupPost.first_comment_id,
                              firstReply
                            )
                          "
                        ></i>
                      </div>
                    </div>
                  </div>
                </li>

                <li
                  class="smp_commented_user items-center"
                  v-for="comment of groupPost.comments"
                  :key="comment.comments_commentsId"
                >
                  <div class="parent-userDplabl">
                    <div class="mr-3">
                      <vs-avatar
                        v-if="comment.usersDetails_UserImage != null"
                        class="m-0"
                        :src="magittUrl + comment.usersDetails_UserImage"
                        size="30px"
                        :text="comment.usersDetails_UserName"
                      />
                      <vs-avatar
                        v-if="comment.usersDetails_UserImage == null"
                        class="bg-primary"
                        size="30px"
                        :text="comment.usersDetails_UserName"
                      />
                      <!-- <vs-avatar
                      class="m-0"
                      :src="magittUrl + comment.usersDetails_UserImage"
                      size="30px"
                    />-->
                    </div>
                    <div class="smp_leading_tight smp_textsocial">
                      <p
                        class="font-medium h4-class smp_cursor-pointer cust-pt-pb cust-pb-5"
                      >
                        {{ comment.usersDetails_UserName }}
                      </p>
                      <span class="post-comm-spn">{{
                        comment.comments_comment
                      }}</span>
                    </div>
                  </div>
                  <ul class="smp_user-comments-list" v-if="comment.showReplies">
                    <li
                      class="smp_commented_user items-center ml-2 smp_text_pddd"
                      v-for="comment of comment.replies"
                      :key="comment.replies_id"
                    >
                      <div class="parent-userDplabl">
                        <div class="mr-3">
                          <vs-avatar
                            v-if="comment.usersDetails_UserImage != null"
                            class="m-0"
                            :src="magittUrl + comment.usersDetails_UserImage"
                            size="30px"
                            :text="comment.usersDetails_UserName"
                          />
                          <vs-avatar
                            v-if="comment.usersDetails_UserImage == null"
                            class="bg-primary"
                            size="30px"
                            :text="comment.usersDetails_UserName"
                          />
                        </div>
                        <div class="smp_leading_tight smp_textsocial">
                          <p
                            class="font-medium h4-class smp_cursor-pointer cust-pt-pb cust-pb-5"
                          >
                            {{ comment.usersDetails_UserName }}
                          </p>
                          <span class="post-comm-spn">{{
                            comment.replies_comment
                          }}</span>
                        </div>
                      </div>
                    </li>
                  </ul>
                  <div class>
                    <div class="flex">
                      <div class="replybtn-parent cursor-pointer mb-2">
                        <div class="reply-btn" v-if="!comment.showReply">
                          <a @click.stop="comment.showReply = true">Reply</a>
                        </div>
                      </div>
                      <div class="replybtn-parent cursor-pointer mb-2">
                        <div
                          class="reply-btn"
                          v-if="comment.REPLYCOUNT > 0 && !comment.showReplies"
                        >
                          <a
                            @click.stop="
                              getReplies(comment.comments_commentsId)
                            "
                            >({{ comment.REPLYCOUNT }}) Replies</a
                          >
                        </div>
                      </div>
                    </div>
                    <div class="replybtn-input ml-10 relative flex">
                      <vs-input
                        v-if="comment.showReply"
                        size="small"
                        class="reply-inputbox"
                        placeholder
                        v-model="comment.newReply"
                        @keydown.enter="
                          addReply(
                            groupPost.comments_postId,
                            comment.comments_commentsId,
                            comment.newReply
                          )
                        "
                      />
                      <div class="indiv-repindivreply-sendbtn">
                        <i
                          icon="icon-send"
                          class="feather icon-send feather-20 text-primary"
                          v-if="comment.showReply"
                          @click="
                            addReply(
                              groupPost.comments_postId,
                              comment.comments_commentsId,
                              comment.newReply
                            )
                          "
                        ></i>
                      </div>
                    </div>
                  </div>
                </li>
              </ul>
            </div>
            <div class="smp_post_comment smp_socl_mdl_cm groupspage_text">
              <vs-input
                class="smp_plce_comment smp_cmnt_border smp_social_page_mg_btt ml-2"
                placeholder="Write a commments......"
                vs-placeholder="Nombre"
                v-model="groupPost.newComment"
                @keydown.enter="
                  addComment(
                    groupPost.post_UniqueId,
                    groupPost.newComment,
                    groupPost.usersDetails_UniqueId
                  )
                "
              />
              <div class="individualsend_icon_setting">
                <a v-if="groupPost.newComment != ''" class>
                  <i
                    class="feather icon-send feather-20 text-primary"
                    icon="icon-send"
                    @click="
                      addComment(
                        groupPost.post_UniqueId,
                        groupPost.newComment,
                        groupPost.usersDetails_UniqueId
                      )
                    "
                  ></i>
                </a>
              </div>
            </div>
          </div>
        </div>
      </vx-card>
      <!-- <div class="flex justify-between smp_mt_4 mt-4 group_page_comment_like">
        <div class="flex items-center ml-2">
          <div class="flex items-center">
            <feather-icon class="mr-2 groups_like_icon" icon="HeartIcon"></feather-icon>
            <span>Likes</span>
          </div>
        </div>
        <div class="flex items-center mr-8 group_page_comment_icon">
          <feather-icon class="mr-2 primary-icon" icon="MessageSquareIcon" svgClasses="h-5 w-5"></feather-icon>
          <span></span>
        </div>
      </div>

      <div class="mt-4">
        <vs-input
          icon-pack="feather"
          icon="icon-send"
          icon-after
          placeholder="icon-after"
          vs-placeholder="Nombre"
          class="w-full"
          v-model="value2"
        />
      </div>-->
    </div>

    <!-- <div
      class="vx-col lg:w-1/4 hiddin-moblView w-full cust-height indiv-left-w card-bg"
    ></div>-->
  </div>
</template>

<script>
import { videoPlayer } from "vue-video-player";
import "video.js/dist/video-js.css";
import { RepositoryFactory } from "@/Core/Apicall/RepositoryFactory";
const postsRepository = RepositoryFactory.get("posts");
import "@/Core/Views/Commoncss/componentCss/allSidebar.css";
import "@/Core/Views/Commoncss/componentCss/GroupPage.css";
import jwt from "jsonwebtoken";
import { currentCommunityName } from "@/Core/config/communityDetails.js";
import LinkPrevue from "link-prevue";
require("videojs-youtube");
import * as moment from "moment";
// import "quill/dist/quill.core.css";
// import "quill/dist/quill.snow.css";
// import "quill/dist/quill.bubble.css";
import PollView from "../group/components/PollView";
const notificationRepository = RepositoryFactory.get("notifications");
const promotionsRepository = RepositoryFactory.get("promotions");
import UserAvatar from "@/Core/Views/CommonPlugins/user-avatar/UserAvatar";
import DownloadDocuments from "@/Core/Views/CommonPlugins/documents/DownloadDocuments";
import "../AddPost/blog.scss";
export default {
  metaInfo() {
    return {
      title: this.metaTitle,
      meta: [
        {
          name: "description",
          content: this.metaDescription ? this.metaDescription : this.metaTitle,
        },
        {
          property: "og:title",
          content: this.metaTitle,
        },
        {
          property: "og:image",
          content: this.metaImage,
        },
        {
          property: "og:url",
          content: `${window.location.href}`,
        },
        { property: "og:type", content: "website" },
      ],
    };
  },
  data() {
    return {
      queryParams: {},
      token: {},
      groupPost: {},
      text: window.location.href,
      firstReply: "",
      showReply: false,
      showReplies: false,
      displayName: "Kotak Treasury",
      isLoaded: false,
      postIDFromShortURL: null,
      viewUniqueId: null,
    };
  },
  computed: {
    playerOptions() {
      return (media) => {
        return {
          height: "360",
          fluid: true,
          autoplay: false,
          muted: false,
          language: "en",
          playbackRates: [0.7, 1.0, 1.5, 2.0],
          sources: [{ src: media.video_url }],
          poster: media.poster,
        };
      };
    },
    playerOptionsAudio() {
      return (media) => {
        return {
          height: "360",
          fluid: true,
          autoplay: false,
          muted: false,
          language: "en",
          playbackRates: [0.7, 1.0, 1.5, 2.0],
          sources: [{ src: media.audio_url }],
          poster:
            this.magittUrl +
            "uploads/defaultImages/default_audio_post_wide.jpg",
        };
      };
    },
    playerOptionsYoutube() {
      return (media) => {
        return {
          height: "360",
          fluid: true,
          autoplay: false,
          muted: false,
          language: "en",
          techOrder: ["youtube"],
          playbackRates: [0.7, 1.0, 1.5, 2.0],
          sources: [{ type: "video/youtube", src: media.url }],
        };
      };
    },
    magittUrl() {
      return this.baseUrlMagitt;
    },
    metaTitle() {
      if (this.groupPost.text != null) {
        return this.groupPost.text.substring(0, 60);
      }
      if (this.groupPost.imageText) {
        return this.groupPost.imageText.substring(0, 60);
      }
      if (this.groupPost.videoText) {
        return this.groupPost.videoText.substring(0, 60);
      }
      if (this.groupPost.audioText) {
        return this.groupPost.audioText.substring(0, 60);
      }
      if (this.groupPost.blogText) {
        return this.groupPost.blogText.substring(0, 60);
      }
    },
    metaDescription() {
      if (this.groupPost.body != null) {
        return this.groupPost.body
          .replace(/(<([^>]+)>)/gi, "")
          .substring(0, 160);
      } else {
        return false;
      }
    },
    metaImage() {
      if (this.groupPost.image != null) {
        return this.groupPost.image;
      } else if (this.groupPost.featured_image != null) {
        return this.groupPost.featured_image;
      } else {
        return `${window.document.location.origin}/logo.png`;
      }
    },
    copyText() {
      return `${this.metaTitle} - ${this.text}`;
    },
  },
  methods: {
    openLoadingContained() {
      this.$vs.loading({
        color: this.colorLoading,
        container: "#button-with-loading",
        scale: 0.45,
      });
      setTimeout(() => {
        this.$vs.loading.close("#button-with-loading > .con-vs-loading");
      }, 3000);
    },
    getPost() {
      let obj = {
        userData: {
          id: this.token.userID,
          uuid: this.token.uuid,
        },
        groupData: {
          id: this.queryParams.postId,
        },
        communityData: {
          communityName: currentCommunityName.communityName,
        },
      };
      return new Promise(async (resolve, reject) => {
        try {
          this.$vs.loading();

          const { data } = await postsRepository.getPost(obj);

          if (data) {
            this.postLoaded = true;
            //        ////debugger
            this.$vs.loading.close();
            if (data.length > 0) {
              if (data[0].post_isDeleted == 1) {
                this.$router.push("/pages/PostNotFound");
              } else {
                data.forEach((element) => {
                  // if (
                  //   moment().diff(
                  //     moment(element.post_group_mapping_created_datetimestamp),
                  //     "hours"
                  //   ) < 13
                  // ) {
                  element.post_group_mapping_created_datetimestamp = moment(
                    element.post_group_mapping_created_datetimestamp
                  ).fromNow();
                  // } else {
                  //   element.post_group_mapping_created_datetimestamp = moment(
                  //     element.post_group_mapping_created_datetimestamp
                  //   ).format("DD-MM-YYYY HH:mm");
                  // }
                  if (element.image != null) {
                    if (element.image.includes("uploads/postImages")) {
                      element.image = this.magittUrl + element.image;
                    }
                  }
                  if (element.video_url != null) {
                    if (element.video_url.includes("uploads/postVideos")) {
                      element.video_url = this.magittUrl + element.video_url;
                    }
                  }
                  if (element.audio_url != null) {
                    if (element.audio_url.includes("uploads/postAudios")) {
                      element.audio_url = this.magittUrl + element.audio_url;
                    }
                  }
                  if (element.featured_image != null) {
                    if (element.featured_image.includes("uploads/postImages")) {
                      element.featured_image =
                        this.magittUrl + element.featured_image;
                    }
                  }
                  if (element.PollQuestion !== null) {
                    element.VotedIds =
                      element.VotedIds !== null ? element.VotedIds : "";
                    element.AnswersIds =
                      element.AnswersIds !== null
                        ? element.AnswersIds.split(",")
                        : [];
                    element.PollAnswers =
                      element.PollAnswers !== null
                        ? element.PollAnswers.split(",")
                        : [];
                    element.AnswersVotes =
                      element.AnswersVotes !== null
                        ? element.AnswersVotes.split(",")
                        : [];
                  }
                  element.comments = [];
                  element.newComment = "";
                  element.comments = [];
                  element.newComment = "";
                  if (!this.$acl.check("admin")) {
                    if (element.first_comment_user_id != this.token.userID) {
                      element.first_comment = null;
                      element.REPLYCOUNT = 0;
                    } else {
                      element.first_comment_replies = [];
                    }
                  } else {
                    element.first_comment_replies = [];
                  }
                  this.groupPost = element;
                });
              }
              resolve(true);
            } else {
              //       ////debugger
              resolve(false);
            }
          } else {
            resolve(false);
          }
        } catch (error) {
          this.$vs.loading.close();
          reject(error);
        }
      });
    },
    async getComments() {
      let obj = {
        communityName: currentCommunityName.communityName,
        postId: this.queryParams.postId,
        limit: 10000,
        offset: 1,
      };

      const { data } = await postsRepository.getComments(obj);

      if (data.ResponseCode == 100) {
        // if (!this.$acl.check("admin")) {
        //   this.groupPost.comments = data.Data.filter(x => x.comments_userId != this.token.uuid)
        // } else {
        //   this.groupPost.comments = data.Data;
        // }
        data.Data.forEach((element) => {
          if (!this.$acl.check("admin")) {
            if (element.comments_userId == this.token.uuid) {
              element.showReply = false;
              element.showReplies = false;
              element.newReply = "";
              element.replies = [];
              this.groupPost.comments.push(element);
            }
          } else {
            element.showReply = false;
            element.showReplies = false;
            element.newReply = "";
            element.replies = [];
            this.groupPost.comments.push(element);
          }
        });
      }
    },
    async likePost(status, postId, likeId, userId, postuid, uuid) {
      if (likeId) {
        this.$vs.loading();
        let self = this;
        let payload = {
          likeData: {
            id: likeId,
            isActive: !status,
            ownerName: self.token.username,
          },
          communityData: {
            communityName: currentCommunityName.communityName,
          },
        };
        const { data } = await postsRepository.editLikedPost(payload);

        if (data.ResponseCode == 100) {
          this.$vs.loading.close();
          this.groupPost.is_active = !status;
          if (!status) {
            this.groupPost.LIKECOUNT += 1;
            this.insertLikeNotification(postuid, uuid);
          } else {
            this.groupPost.LIKECOUNT -= 1;
          }
        } else {
          this.$vs.loading.close();
          self.$vs.notify({
            title: "Error",
            text: "Something went wrong",
            iconPack: "feather",
            icon: "icon-alert-circle",
            color: "danger",
          });
        }
      } else {
        this.$vs.loading();
        let self = this;
        let payload = {
          likeData: {
            postId: postuid,
            groupId: null,
            userId: self.token.uuid,
            isActive: 1,
            ownerName: self.token.username,
          },
          communityData: {
            communityName: currentCommunityName.communityName,
          },
        };
        const { data } = await postsRepository.likePost(payload);

        if (data.ResponseCode == 100) {
          this.$vs.loading.close();
          this.groupPost.is_active = 1;
          this.groupPost.likeId = data.insertId;
          if (!status) {
            this.groupPost.LIKECOUNT += 1;
            this.insertLikeNotification(postuid, uuid);
          } else {
            this.groupPost.LIKECOUNT -= 1;
          }
        } else {
          this.$vs.loading.close();
          self.$vs.notify({
            title: "Error",
            text: "Something went wrong",
            iconPack: "feather",
            icon: "icon-alert-circle",
            color: "danger",
          });
        }
      }
    },
    async insertLikeNotification(postId, userId) {
      if (this.token.uuid != userId) {
        let payload = {
          notificationData: {
            initiaterId: this.token.uuid,
            initiaterName: this.token.username,
            initiaterImage: this.token.userImage,
            recepientId: userId,
            recepientType: 1,
            notificationMessage: "liked your post",
            notificationTitle: "Likes",
            notificationEntityType: "Post",
            notificationEntityId: postId,
            notificationReadStatus: 0,
          },
          communityData: {
            communityName: currentCommunityName.communityName,
          },
        };
        await notificationRepository.addNotification(payload);
      }
    },
    async addComment(postId, comment, uuid) {
      this.$vs.loading();
      let obj = {
        communityName: currentCommunityName.communityName,
        postId: postId,
        userId: this.token.uuid,
        comment: comment,
        commenterName: this.token.username,
      };

      const { data } = await postsRepository.insertComment(obj);

      if (data.ResponseCode == 100) {
        this.$vs.loading.close();
        if (!this.groupPost.first_comment) {
          this.groupPost.first_comment = comment;
          this.groupPost.first_comment_user_name = this.token.username;
          this.groupPost.first_comment_user_image = this.token.userImage;
        } else {
          this.groupPost.comments.push({
            usersDetails_UserImage: this.token.userImage,
            usersDetails_UserName: this.token.username,
            comments_comment: comment,
          });
        }
        this.insertCommentNotification(postId, uuid);
        this.groupPost.newComment = "";
        this.groupPost.comment_count =
          this.groupPost.comment_count == null
            ? 1
            : parseInt(this.groupPost.comment_count) + 1;
      } else {
        this.$vs.loading.close();
      }
    },
    async insertCommentNotification(postId, userId) {
      if (this.token.uuid != userId) {
        let payload = {
          notificationData: {
            initiaterId: this.token.uuid,
            initiaterName: this.token.username,
            initiaterImage: this.token.userImage,
            recepientId: userId,
            recepientType: 1,
            notificationMessage: "commented on your post",
            notificationTitle: "Comments",
            notificationEntityType: "Post",
            notificationEntityId: postId,
            notificationReadStatus: 0,
          },
          communityData: {
            communityName: currentCommunityName.communityName,
          },
        };
        await notificationRepository.addNotification(payload);
      }
    },
    async addReplyFirstComment(postId, commentId, reply) {
      this.$vs.loading();
      let obj = {
        communityName: currentCommunityName.communityName,
        postId: postId,
        commentId: commentId,
        reply: reply,
        commenterName: this.token.username,
        userId: this.token.uuid,
      };

      const { data } = await postsRepository.insertReply(obj);

      if (data.ResponseCode == 100) {
        this.$vs.loading.close();
        this.groupPost.first_comment_replies.push({
          usersDetails_UserImage: this.token.userImage,
          usersDetails_UserName: this.token.username,
          replies_comment: reply,
        });
        this.showReplies = true;
        this.getFirstReplies(commentId);
        this.firstReply = "";
      } else {
        this.$vs.loading.close();
      }
    },
    async addReply(postId, commentId, reply) {
      this.$vs.loading();
      let obj = {
        communityName: currentCommunityName.communityName,
        postId: postId,
        commentId: commentId,
        reply: reply,
        commenterName: this.token.username,
        userId: this.token.uuid,
      };

      const { data } = await postsRepository.insertReply(obj);

      if (data.ResponseCode == 100) {
        this.$vs.loading.close();
        this.groupPost.comments[
          this.groupPost.comments.findIndex(
            (x) => x.comments_commentsId == commentId
          )
        ].replies.push({
          usersDetails_UserImage: this.token.userImage,
          usersDetails_UserName: this.token.username,
          replies_comment: reply,
        });
        this.groupPost.comments[
          this.groupPost.comments.findIndex(
            (x) => x.comments_commentsId == commentId
          )
        ].showReplies = true;
        this.groupPost.comments[
          this.groupPost.comments.findIndex(
            (x) => x.comments_commentsId == commentId
          )
        ].newReply = "";
      } else {
        this.$vs.loading.close();
      }
    },
    async getFirstReplies(commentId) {
      this.$vs.loading();
      let obj = {
        communityName: currentCommunityName.communityName,
        commentId: commentId,
      };

      const { data } = await postsRepository.getReplies(obj);

      if (data.ResponseCode == 100) {
        // if (!this.$acl.check("admin")) {
        //   this.groupPost.comments = data.Data.filter(x => x.comments_userId != this.token.uuid)
        // } else {
        //   this.groupPost.comments = data.Data;
        // }
        this.$vs.loading.close();
        this.showReplies = true;
        data.Data.forEach((element) => {
          this.groupPost.first_comment_replies.push(element);
        });
      } else {
        this.$vs.loading.close();
      }
    },
    async getReplies(commentId) {
      this.$vs.loading();
      let obj = {
        communityName: currentCommunityName.communityName,
        commentId: commentId,
      };

      const { data } = await postsRepository.getReplies(obj);

      if (data.ResponseCode == 100) {
        // if (!this.$acl.check("admin")) {
        //   this.groupPost.comments = data.Data.filter(x => x.comments_userId != this.token.uuid)
        // } else {
        //   this.groupPost.comments = data.Data;
        // }
        this.$vs.loading.close();
        this.groupPost.comments[
          this.groupPost.comments.findIndex(
            (x) => x.comments_commentsId == commentId
          )
        ].showReplies = true;
        data.Data.forEach((element) => {
          this.groupPost.comments[
            this.groupPost.comments.findIndex(
              (x) => x.comments_commentsId == commentId
            )
          ].replies.push(element);
        });
      } else {
        this.$vs.loading.close();
      }
    },
    async addViewedPromotionLog() {
      let payload = {
        communityName: currentCommunityName.communityName,
        userId: this.token.uuid,
        postId: this.queryParams.postId,
        name: this.token.username,
        startTime: new Date().toISOString().replace("T", " ").replace("Z", ""),
      };
      const { data } = await promotionsRepository.addViewedPromotion(payload);
      if (data) {
        this.viewUniqueId = data.uniqueId;
      }
    },
    async updateViewedPromotionLog() {
      let payload = {
        communityName: currentCommunityName.communityName,
        uniqueId: this.viewUniqueId,
        endTime: new Date().toISOString().replace("T", " ").replace("Z", ""),
      };
      promotionsRepository.updateViewedPromotion(payload);
    },
    async updateCRNLog() {
      if (this.queryParams.crn) {
        let payload = {
          crn: this.queryParams.crn,
          communityData: {
            communityName: "Kotak Treasury",
          },
          postid: this.queryParams.postId,
        };
        postsRepository.updatePostByCRN(payload);
      }
      // promotionsRepository.updateViewedPromotion(payload);
    },
    async addSharedPromotionLog(medium) {
      let payload = {
        communityName: currentCommunityName.communityName,
        userId: this.token.uuid,
        postId: this.queryParams.postId,
        medium: medium,
        name: this.token.username,
      };
      await promotionsRepository.addSharedPromotion(payload);
    },
    socialShareOpen(network) {
      this.addSharedPromotionLog(network);
    },
    getParams(fullurl) {
      const url = new URL(fullurl);
      return url.searchParams.get("pid");
    },
    getFullURL() {
      this.$vs.loading();
      let payload = {
        shortUrl: this.$route.params.id,
      };
      this.$store.dispatch("shortURL/getFullUrl", payload).then(() => {
        this.$vs.loading.close();
        const fullurl = this.$store.state.shortURL.url.full;

        this.postIDFromShortURL = this.getParams(fullurl);
        this.loadContent();
        //window.location.replace(this.$store.state.shortURL.url.full);
      });
    },
    getShortURL() {
      let payload = {
        full: window.location.href,
      };
      this.$store.dispatch("shortURL/addShortUrl", payload).then(() => {
        this.text = `${window.document.location.origin}/posts/${this.$store.state.shortURL.url.short}`;
      });
    },
    loadContent() {
      const postID = this.postIDFromShortURL
        ? this.postIDFromShortURL
        : this.$route.query.pid;
      const crn = this.postIDFromShortURL
        ? this.postIDFromShortURL
        : this.$route.query.crn;
      if (localStorage.getItem("token")) {
        setTimeout(() => {
          if (this.$router.currentRoute.params.groupPost) {
            this.groupPost = this.$router.currentRoute.params.groupPost;
          } else if (postID) {
            this.queryParams.postId = postID;
            this.queryParams.crn = crn;
            this.getPost().then((data) => {
              if (data) {
                this.getComments(this.groupPost.post_UniqueId);

                // Dont generate new short ID if already present
                if (!this.$route.params.id) {
                  this.getShortURL();
                }

                this.addViewedPromotionLog();
                if (crn) {
                  this.updateCRNLog();
                }
              }
            });
          }
          this.isLoaded = true;
        }, 1000);
      } else {
        this.$router
          .push({
            name: "page-kotak-login",
            params: {
              toIndividualPost: true,
              pid: postID,
              crn: crn,
            },
          })
          .catch(() => {});
      }
    },
  },
  mounted() {
    // // this.$_mountTime = date.now();
    window.onbeforeunload = window.addEventListener(
      "beforeunload",
      this.updateViewedPromotionLog
    );
  },
  created() {
    if (this.$route.params.id) {
      this.getFullURL();
    } else {
      this.loadContent();
    }

    let newtoken = localStorage.getItem("token");
    this.token = jwt.decode(newtoken);
  },
  beforeDestroy() {
    // const name = this.$options.name; // component name.
    // const elapsed = Date.now() - this.$_mountTime;
    // send data to API somehow, i.e. through a Store action or simply use axios or sth.
    // this.updateViewedPromotionLog();
    // this.$store.dispatch('trackTimeSpent', {
    //   name,
    //   elapsed,
    // })
  },
  components: {
    videoPlayer,
    LinkPrevue,
    PollView,
    UserAvatar,
    DownloadDocuments,
  },
};
</script>

<style>
.indi-height {
  height: 98vh;
  overflow-y: auto;
  overflow-x: hidden;
}
/* .indiv-left-w {
  position: fixed;
  left: 25%; */
/* margin-top: 10px;
}*/
@media (max-width: 505px) {
  .hiddin-moblView {
    display: none;
  }
  .indiv-left-w {
    margin-top: 0px;
  }

  .indi-height {
    height: 95vh !important;
  }
}
.comment-box {
  width: 100%;
}
.indiv-icon_setting {
  transform: rotate(45deg);
  right: 17px;
  font-size: 22px;
  bottom: 20px;
  position: absolute;
}
.t-center {
  text-align: center;
}
.mg-all-0 {
  margin: 0 0rem !important;
}
.indi-height::-webkit-scrollbar {
  width: 0;
}
.ind-div-pd {
  padding-top: 10px;
}
.onboard-socialicon {
  /* background: #f4f5f6; */
  justify-content: left;
  display: flex;
  padding: 10px;
  border-radius: 50px;
}
.social-icon-img {
  height: 30px;
  width: 30px;
  margin-left: 10px;
}
.cust-pb-5 {
  padding-bottom: 4px;
}

.blog-body {
  text-align: justify;
  /*letter-spacing: 1px;
  word-spacing: 4px;*/
}
.indiv-left-w {
  display: flex;
  justify-content: center;
  width: 70% !important;
}
@media (max-width: 769px) {
  .indiv-left-w {
    width: 95% !important;
    margin-top: 10px;
    margin-bottom: 10px;
  }
  .cust-indmb {
    margin: 5px !important;
  }
}

@media (min-width: 576px) {
  .cust-indmb {
    margin: 10px !important;
  }
}
.cust-negtop {
  margin-top: -8px !important;
}
.whatsapp-color {
  background: #25d366;
}
.fsize12 {
  font-size: 12px;
}
</style>
